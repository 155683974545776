import React from 'react';
import { values, flatten } from 'ramda';
import PropTypes from 'prop-types';
import qs from 'qs';
import cn from 'clsx';
import { CheckBox, Anchor } from 'grommet';

import AnchorLink from 'components/grommet/AnchorLink';
import QuestionMarkButton from 'components/QuestionMarkButton';
import ProfileAvatar from 'components/ProfileAvatar';
import Fortune500BoardMemberBadge from 'components/client/Fortune500BoardMemberBadge';
import Ftse100BoardMemberBadge from 'components/client/Ftse100BoardMemberBadge';
import NewToRoleBadge from 'components/client/NewToRoleBadge';
import RawHtml from 'components/RawHtml';
import CurrentWork from './components/CurrentWork';

import ProfilePresenter from 'presenters/ProfileSearchPresenter';
import UserPresenter from 'presenters/UserPresenter';

import styles from './ProfileBlock.module.css';

import Routes from 'routes';

const ProfileBlock = (props) => {
  const { profile, onSelectProfile, highlights, isSelected, highlightedKeywords, showSelectBox, currentUser } = props;
  const isMaskedProfile = ProfilePresenter.isMasked(profile);

  const currentWorks = ProfilePresenter.currentWorks(profile);
  const search = qs.stringify({ keywords: highlightedKeywords }, { arrayFormat: 'brackets' });

  const usernameClassNames = cn(styles.username, { [styles.usernameMasked]: isMaskedProfile });
  const userpicClassNames = cn(styles.userpic, { [styles.userpicMasked]: isMaskedProfile });
  const highlightsClassNames = cn(styles.highlights, { [styles.highlightsMasked]: isMaskedProfile });

  const handleSelectProfile = () => onSelectProfile(profile.id);

  const tooltipText = () => (
    <span>
      This profile is not included in your limited access subscription but you may request access by clicking on the
      profile. If you want expanded access to our full database, please{' '}
      <Anchor href={`mailto:${UserPresenter.managerEmail(currentUser)}`} className={styles.tooltipAnchor}>
        contact your account manager.
      </Anchor>
    </span>
  );

  return (
    <div className={styles.item}>
      {showSelectBox && (
        <div className={styles.action}>
          <CheckBox checked={isSelected} disabled={isMaskedProfile} onChange={handleSelectProfile} />
        </div>
      )}
      <AnchorLink to={Routes.profilePath(ProfilePresenter.slug(profile))}>
        <ProfileAvatar className={userpicClassNames} src={ProfilePresenter.photoUrl(profile)} />
      </AnchorLink>
      <div className={styles.info}>
        <div className={styles.header}>
          <AnchorLink
            to={{ pathname: Routes.profilePath(ProfilePresenter.slug(profile)), search }}
            className={usernameClassNames}
          >
            {ProfilePresenter.fullName(profile)}
          </AnchorLink>
          <div className={styles.labels}>
            <div className={styles.profileLabels}>
              {ProfilePresenter.isNewToRole(profile) && (
                <div>
                  <NewToRoleBadge />
                </div>
              )}

              {ProfilePresenter.isMemberFortune500(profile) && (
                <div>
                  <Fortune500BoardMemberBadge />
                </div>
              )}
              {ProfilePresenter.isMemberFtse100(profile) && (
                <div>
                  <Ftse100BoardMemberBadge />
                </div>
              )}
            </div>
            <div className={styles.markButton}>
              {isMaskedProfile && (
                <QuestionMarkButton
                  onClick={() => {}}
                  text={tooltipText}
                  overlayStyle={{ width: 200, wordBreak: 'break-word' }}
                  placement="left"
                />
              )}
            </div>
          </div>
        </div>
        {currentWorks.map((currentWork) => (
          <CurrentWork work={currentWork} isMasked={isMaskedProfile} key={currentWork.id} />
        ))}
        {flatten(values(highlights)).map((highlight) => (
          <RawHtml className={highlightsClassNames} innerHTML={highlight} />
        ))}
      </div>
    </div>
  );
};

ProfileBlock.propTypes = {
  profile: ProfilePresenter.shape(),
  highlights: PropTypes.shape({}),
  highlightedKeywords: PropTypes.arrayOf(PropTypes.string),
  onSelectProfile: PropTypes.func,
  isSelected: PropTypes.bool.isRequired,
  showSelectBox: PropTypes.bool,
  currentUser: UserPresenter.shape(),
};

ProfileBlock.defaultProps = {
  onSelectProfile: () => {},
  highlights: {},
  highlightedKeywords: [],
  showSelectBox: true,
};

export default ProfileBlock;
