export default {
  biProDemoRequestUrl: () => `//info.boardroominsiders.com/pro-lp#BIPRODemoReq`,
  biProUrl: () => '//info.boardroominsiders.com/pro-lp',
  articlesUrl: () => '//boardroominsiders.com/category/articles',
  contactUsUrl: () => '//info2.boardroominsiders.com/boardroom-insiders-demo-request',
  privacyUrl: () => '//boardroominsiders.com/privacy-policy',
  requestDemoUrl: () => '//info.boardroominsiders.com/request-a-demo_homepage_lp',
  guidesUrl: () => '//boardroominsiders.com/category/guides-2',
  productsUrl: () => '//boardroominsiders.com/products',
  twitterUrl: () => '//twitter.com/AltrataOfficial',
  linkedinUrl: () => '//www.linkedin.com/company/altrata',
  termsOfUseUrl: () => '//www.delinian.com/terms-and-conditions',
  slaveryStatementUrl: () => '//www.delinian.com/modern-slavery-act/',
};
