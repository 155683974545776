import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';
import { goToTop } from 'utils/DOMHelpers';

import Pagination from 'containers/UserPanel/components/Pagination';
import ProfileBlock from './components/ProfileBlock';

import ProfilePresenter from 'presenters/ProfileSearchPresenter';
import UserPresenter from 'presenters/UserPresenter';

import styles from './ProfilesList.module.css';

class ProfilesList extends Component {
  static propTypes = {
    currentPage: PropTypes.number,
    isSelectable: PropTypes.bool.isRequired,
    maxPages: PropTypes.number,
    highlights: PropTypes.shape(),
    highlightedKeywords: PropTypes.arrayOf(PropTypes.string),
    onLoadPage: PropTypes.func.isRequired,
    onProfileSelect: PropTypes.func,
    perPage: PropTypes.number.isRequired,
    profiles: PropTypes.arrayOf(ProfilePresenter.shape()).isRequired,
    selectedProfileIds: PropTypes.arrayOf(PropTypes.number),
    totalCount: PropTypes.number,
    currentUser: UserPresenter.shape(),
  };

  static defaultProps = {
    highlights: {},
    highlightedKeywords: [],
    selectedProfileIds: [],
  };

  isProfileSelected(profile) {
    const { selectedProfileIds } = this.props;

    return selectedProfileIds.includes(profile.id);
  }

  handleLoadPage = (page) => {
    const { onLoadPage } = this.props;

    onLoadPage(page);
    goToTop();
  };

  render() {
    const {
      currentPage,
      isSelectable,
      maxPages,
      highlights,
      highlightedKeywords,
      onProfileSelect,
      perPage,
      profiles,
      totalCount,
      currentUser,
    } = this.props;

    return (
      <>
        <div>
          {profiles.map((profile) => (
            <ProfileBlock
              key={profile.id}
              profile={profile}
              onSelectProfile={onProfileSelect}
              highlights={highlights[profile.id]}
              isSelected={this.isProfileSelected(profile)}
              highlightedKeywords={highlightedKeywords}
              showSelectBox={isSelectable}
              currentUser={currentUser}
            />
          ))}
        </div>
        {isPresent(currentPage) && isPresent(totalCount) && (
          <div className={styles.pagination}>
            <Pagination
              currentPage={currentPage}
              perPage={perPage}
              totalCount={totalCount}
              maxPages={maxPages}
              onPageChange={this.handleLoadPage}
            />
          </div>
        )}
      </>
    );
  }
}

export default ProfilesList;
