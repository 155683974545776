import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil, keys, propOr } from 'ramda';
import clsx from 'clsx';

import { isPresent } from 'utils/HelperMethods';

import Typography from 'components/v2/Typography';
import { SWITCH_ROLE } from 'components/v2/Form/components/Switch';
import { CHECKBOX_ROLE } from 'components/v2/Form/components/Checkbox';

import FieldContext from './FieldContext';

import styles from './Field.module.css';

const VARIANTS = {
  outlined: styles.outlined,
  default: styles.default,
};

const isChildrenRole = (element, role) => element?.type?.fieldRole === role;

const Field = (props) => {
  const { children, className, error, title, variant } = props;
  const errorMessage = Array.isArray(error) ? error.join(', ') : error;
  const hasError = !isNil(error) && !isEmpty(errorMessage);
  const variantClassName = propOr(VARIANTS.default, variant)(VARIANTS);
  const fieldClassName = clsx(styles.field, variantClassName, {
    [styles.error]: hasError,
    [styles.switchField]: isChildrenRole(children, SWITCH_ROLE),
    [styles.checkboxField]: isChildrenRole(children, CHECKBOX_ROLE),
  });
  const fieldContainerClassName = clsx(styles.fieldContainer, className);
  const childContext = useMemo(() => ({ variant }), [variant]);

  /* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */
  return (
    <div className={fieldContainerClassName}>
      <label className={fieldClassName}>
        {title && (
          <Typography className={styles.title} variant="body2">
            {title}
          </Typography>
        )}
        <div className={styles.input}>
          <FieldContext.Provider value={childContext}>{children}</FieldContext.Provider>
        </div>
      </label>
      {isPresent(errorMessage) && <span className={styles.errorMessage}>{errorMessage}</span>}
    </div>
  );
  /* eslint-enable jsx-a11y/label-has-for */
};

Field.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.node]),
  title: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  variant: PropTypes.oneOf(keys(VARIANTS)),
};

Field.defaultProps = {
  className: undefined,
  error: null,
  title: null,
  variant: 'default',
};

export default Field;
