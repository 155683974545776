import React from 'react';
import PropTypes from 'prop-types';
import { has } from 'ramda';
import { TextInput } from 'grommet';

import config from 'config';

import Button from 'components/Button';
import Icon from 'components/Icon';
import ScopeFilter from 'containers/UserPanel/components/SearchScopeFilter';
import SortSelect from 'containers/UserPanel/components/SearchSortSelect';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import styles from './BaseFilters.module.css';

const BaseFilters = (props) => {
  const { filters, onSearchQueryChange, onSortChange, onScopeFilterChange, onSidebarOpen } = props;
  const { scopeFilterDate, scopeFilterValue, sort, query } = filters;

  const handleSearchQueryChange = ({ target: { value } }) => onSearchQueryChange(value || '');

  return (
    <div className={styles.search}>
      {has('query', filters) && (
        <div className={styles.searchField}>
          <VerticalFormFieldWithBottomErrors label="Quick Search">
            <TextInput
              onChange={handleSearchQueryChange}
              value={query}
              placeholder="Company name, Executive name, Title"
              maxLength={config.search.queryValueMaxLength}
            />
          </VerticalFormFieldWithBottomErrors>
        </div>
      )}
      <div className={styles.sortField}>
        <ScopeFilter date={scopeFilterDate} value={scopeFilterValue} onChange={onScopeFilterChange} />
      </div>
      <div className={styles.sortField}>
        <SortSelect placeholder="Sort by" sort={sort} onChange={onSortChange} />
      </div>
      <Button onClick={onSidebarOpen} className={styles.filters}>
        <Icon name="filter" />
      </Button>
    </div>
  );
};

BaseFilters.propTypes = {
  filters: PropTypes.shape({
    query: PropTypes.string,
    scopeFilterDate: PropTypes.string,
    scopeFilterValue: PropTypes.string,
    sort: PropTypes.string.isRequired,
  }).isRequired,
  onSearchQueryChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onScopeFilterChange: PropTypes.func.isRequired,
  onSidebarOpen: PropTypes.func.isRequired,
};

export default BaseFilters;
