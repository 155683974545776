/* eslint-disable no-param-reassign  */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { omit, isEmpty } from 'ramda';

import PublishedProfilesRepository from 'repositories/PublishedProfilesRepository';
import SavedSearchesRepository from 'repositories/CurrentUser/SavedSearchesRepository';
import ProjectsRepository from 'repositories/CurrentUser/ProjectsRepository';
import ProfileFilteredSearchForm, { FILTERS } from 'forms/ProfileFilteredSearchForm';

const isEmptyRamda = isEmpty;
const omitRamda = omit;

const initialState = {
  isExecutivesLoading: true,
  executives: [],
  executivesMeta: {},

  isBoardMembersLoading: true,
  boardMembers: [],
  boardMembersMeta: {},

  isSavedSearchLoading: false,
  isProjectSaving: false,
};

const clientSearch = createSlice({
  name: 'clientSearch',
  initialState,
  reducers: {
    filterExecutivesStart(state) {
      state.isExecutivesLoading = true;
    },
    filterExecutivesSuccess(state, { payload }) {
      const { executives, meta } = payload;

      state.isExecutivesLoading = false;
      state.executives = executives;
      state.executivesMeta = meta;
    },
    filterBoardMembersStart(state) {
      state.isBoardMembersLoading = true;
    },
    filterBoardMembersSuccess(state, { payload }) {
      const { boardMembers, meta } = payload;

      state.isBoardMembersLoading = false;
      state.boardMembers = boardMembers;
      state.boardMembersMeta = meta;
    },
    createProjectStart(state) {
      state.isProjectSaving = true;
    },
    createProjectSuccess(state) {
      state.isProjectSaving = false;
    },
    createProjectError(state) {
      state.isProjectSaving = false;
    },
    loadSavedSearchStart(state) {
      state.isSavedSearchLoading = true;
    },
    loadSavedSearchSuccess(state) {
      state.isSavedSearchLoading = false;
    },
    loadSavedSearchError(state) {
      state.isSavedSearchLoading = false;
    },
  },
});

const { actions } = clientSearch;

function filterExecutives(filters) {
  return (dispatch) => {
    dispatch(actions.filterExecutivesStart());

    const attributes = ProfileFilteredSearchForm.attributesToSubmit(filters);

    return PublishedProfilesRepository.index(attributes).then(({ items, meta }) => {
      dispatch(actions.filterExecutivesSuccess({ executives: items, meta }));

      return meta;
    });
  };
}

function filterBoardMembers(filters) {
  return (dispatch) => {
    const {
      [FILTERS.CURRENT_ORGANIZATION_NAME_FILTER]: currentOrganizationNameFilterValue,
      [FILTERS.CURRENT_ORGANIZATION_RANK_LTE]: currentOrganizationRankLteFilterValue,
      ...restFilters
    } = filters;

    if (isEmptyRamda(currentOrganizationNameFilterValue) && isEmptyRamda(currentOrganizationRankLteFilterValue)) {
      const emptyMeta = { currentPage: 1, nextPage: null, totalCount: 0 };
      dispatch(
        actions.filterBoardMembersSuccess({
          boardMembers: [],
          meta: emptyMeta,
        }),
      );

      return Promise.resolve(emptyMeta);
    }

    dispatch(actions.filterBoardMembersStart());

    const isFtseNeeded =
      isEmptyRamda(currentOrganizationRankLteFilterValue) || currentOrganizationRankLteFilterValue[0].includes('ftse');
    const isFortuneNeeded =
      isEmptyRamda(currentOrganizationRankLteFilterValue) ||
      currentOrganizationRankLteFilterValue[0].includes('fortune');

    const attributes = ProfileFilteredSearchForm.attributesToSubmit({
      ...restFilters,
      ...(isFortuneNeeded && {
        [FILTERS.DIRECTORS_BOARD_ORGANIZATION_NAME_FILTER]: currentOrganizationNameFilterValue,
      }),
      ...(isFtseNeeded && {
        [FILTERS.DIRECTORS_BOARD_ORGANIZATION_NAME_FTSE100_FILTER]: currentOrganizationNameFilterValue,
      }),
      [FILTERS.DIRECTOR_BOARD_ORGANIZATION_RANKING]: currentOrganizationRankLteFilterValue,
    });

    return PublishedProfilesRepository.index(attributes).then(({ items, meta }) => {
      dispatch(actions.filterBoardMembersSuccess({ boardMembers: items, meta }));

      return meta;
    });
  };
}

function loadFilterOptions(filter, value, searchParams) {
  return () => {
    const filters = ProfileFilteredSearchForm.attributesToSubmit(omitRamda([filter], searchParams));
    const params = { ...filters, [filter]: value };

    return PublishedProfilesRepository.autocomplete(params).then((items) => items);
  };
}

function createProject(params) {
  return (dispatch) => {
    dispatch(actions.createProjectStart());

    return ProjectsRepository.create(params)
      .then(({ project }) => {
        dispatch(actions.createProjectSuccess({ project }));

        return project;
      })
      .catch((errors) => {
        dispatch(actions.createProjectError());

        throw errors;
      });
  };
}

function loadSavedSearch(id) {
  return (dispatch) => {
    dispatch(actions.loadSavedSearchStart());

    return SavedSearchesRepository.show(id)
      .then(({ savedSearch }) => {
        dispatch(actions.loadSavedSearchSuccess({ savedSearch }));

        return savedSearch;
      })
      .catch((errors) => {
        dispatch(actions.loadSavedSearchError());

        throw errors;
      });
  };
}

export const useClientSearchActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      filterExecutives,
      filterBoardMembers,
      loadFilterOptions,
      createProject,
      loadSavedSearch,
    },
    dispatch,
  );
};

export default clientSearch.reducer;

/* eslint-enable no-param-reassign  */
