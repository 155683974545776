import PropTypes from 'prop-types';

import Presenter from '../utils/PropTypesPresenter';
import { formattedDate } from 'utils/DateTime';

import CompanyEmailSuffixPresenter from './Company/EmailSuffixPresenter';
import CompanyDepartmentPresenter from './Company/DepartmentPresenter';
import CompanyLegalSettingPresenter from './Company/LegalSettingPresenter';
import SingleSignOnPresenter from './SingleSignOnPresenter';

export const COMPANY_FILTERED_SEARCH_CSV_EXPORT = 'ExportJob::FilteredSearchCompaniesCsvExportJob';

export const STATE_EVENTS = {
  ACTIVATE: 'activate',
  DISABLE: 'disable',
};

export const STATES = {
  ACTIVE: 'active',
  DISABLED: 'disabled',
};

export const STATE_OPTIONS = [
  { label: 'Active', value: STATES.ACTIVE },
  { label: 'Disabled', value: STATES.DISABLED },
];

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    state: PropTypes.string,
    seatsNumber: PropTypes.number,
    usedSeatsNumber: PropTypes.number,
    requestsNumber: PropTypes.number,
    subscriptionStartsAt: PropTypes.string,
    subscriptionExpireAt: PropTypes.string,
    emailSuffices: PropTypes.arrayOf(CompanyEmailSuffixPresenter.shape()),
    remainingSeatsNumber: PropTypes.number,
    createdAt: PropTypes.string,
    activeSeatsNumber: PropTypes.number,
    purchasedRequestsNumber: PropTypes.number,
    deliveredRequestsNumber: PropTypes.number,
    pendingRequestsNumber: PropTypes.number,
    requestedRequestsNumber: PropTypes.number,
    viewsAndDownloadsNumber: PropTypes.number,
    departments: PropTypes.arrayOf(CompanyDepartmentPresenter.shape()),
    showPreviousIntervalData: PropTypes.bool,
    slug: PropTypes.string,
    singleSignOn: SingleSignOnPresenter.shape(),
    manager: PropTypes.shape(),
    legalSetting: CompanyLegalSettingPresenter.shape(),
    registrationFormHeader: PropTypes.string,
    registrationFormVideoCode: PropTypes.string,
    registrationFormWysiwyg: PropTypes.string,
  },
  {
    humanStateName(c) {
      const state = this.state(c);

      return STATE_OPTIONS.find((r) => r.value === state).label;
    },
    isActive(c) {
      return this.state(c) === STATES.ACTIVE;
    },
    formattedSubscriptionExpireAt(c) {
      return formattedDate(this.subscriptionExpireAt(c));
    },
    formattedCreatedAt(c) {
      return formattedDate(this.createdAt(c));
    },
  },
);
