import { defaultTo, pick } from 'ramda';

export default {
  defaultAttributes(company) {
    return {
      name: '',
      ...company,
      emailSuffixesAttributes: company.emailSuffixes,
      registrationFormHeader: defaultTo('', company.registrationFormHeader),
      registrationFormWysiwyg: defaultTo('', company.registrationFormWysiwyg),
      registrationFormVideoCode: defaultTo('', company.registrationFormVideoCode),
      showPreviousIntervalData: defaultTo(false, company.showPreviousIntervalData),
      projectSharingAvailable: defaultTo(false, company.projectSharingAvailable),
      projectSharingToNewCompanyMembersAvailable: defaultTo(false, company.projectSharingToNewCompanyMembersAvailable),
    };
  },

  attributesToSubmit(company) {
    const propsToSend = [
      'name',
      'subscriptionStartsAt',
      'subscriptionExpireAt',
      'registrationFormHeader',
      'registrationFormVideoCode',
      'registrationFormWysiwyg',
      'emailSuffixesAttributes',
      'showPreviousIntervalData',
      'projectSharingAvailable',
      'projectSharingToNewCompanyMembersAvailable',
      'stateEvent',
    ];

    return pick(propsToSend, company);
  },
};
