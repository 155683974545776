import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './ProfileContainerActions';

const mapStateToProps = (state) => ({
  loading: state.ProfileContainerReducer.loading,
  profile: state.ProfileContainerReducer.profile,
  isUpdateRequestSaving: state.ProfileContainerReducer.isUpdateRequestSaving,
  subscriptions: state.ProfileContainerReducer.subscriptions,
  isSubscriptionsSaving: state.ProfileContainerReducer.isSubscriptionsSaving,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

const ProfileContainer = connect(mapStateToProps, mapDispatchToProps);

export default ProfileContainer;
