import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';
import { isNil, equals, reject } from 'ramda';

import { logClickOnSearchPageTabBoardOfDirectors } from 'utils/amplitude';

import AnchorLink from 'components/grommet/AnchorLink';
import ProfilesList from 'containers/UserPanel/components/SearchProfilesList';
import Spinner from 'components/Spinner';
import Tabs, { Tab } from 'components/Tabs';

import Actions from './components/Actions';

import UserPolicy from 'policies/UserPolicy';
import ProfilePolicy from 'policies/ProfilePolicy';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProfilePresenter from 'presenters/ProfileSearchPresenter';

import styles from './Profiles.module.css';

const BOARD_OF_DIRECTORS_TAB_INDEX = 1;

const Profiles = (props) => {
  const {
    boardMembers,
    boardMembersMeta,
    currentUser,
    executives,
    executivesMeta,
    filters,
    isBoardMembersLoading,
    isExecutivesLoading,
    maxPage,
    onBackgroundExport,
    onBoardMembersPageLoad,
    onExecutivesPageLoad,
    perPage,
  } = props;

  const [selectedProfileIds, setSelectedProfileIds] = useState([]);

  useEffect(() => {
    setSelectedProfileIds([]);
  }, [boardMembers, executives]);

  const isAnyExportAvailable =
    ProfilePolicy.canSeeDownloadButton(currentUser) || UserPolicy.canExportSearchResultInCsv(currentUser);

  const handleAllProfileSelection = (ids) => setSelectedProfileIds(ids);

  const handleProfileSelection = (id) => {
    const ids = selectedProfileIds.includes(id) ? reject(equals(id), selectedProfileIds) : [...selectedProfileIds, id];

    return setSelectedProfileIds(ids);
  };

  const handleTabChange = (tabIndex) => {
    if (tabIndex === BOARD_OF_DIRECTORS_TAB_INDEX) logClickOnSearchPageTabBoardOfDirectors();
    setSelectedProfileIds([]);
  };

  const renderTabTitle = (title, count, isLoading) => (isNil(count) || isLoading ? title : `${title} (${count})`);

  if (isExecutivesLoading || isBoardMembersLoading) {
    return (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  }

  return (
    <Tabs fullWidth onActive={handleTabChange} className={styles.tabs}>
      <Tab title={renderTabTitle('Executives', executivesMeta.totalCount, isExecutivesLoading)}>
        {isAnyExportAvailable && (
          <Actions
            onBackgroundExport={onBackgroundExport}
            currentUser={currentUser}
            onSelectedProfileIdsChange={handleAllProfileSelection}
            profiles={executives}
            selectedProfileIds={selectedProfileIds}
            filters={filters}
            keywords={executivesMeta.highlightedKeywords}
          />
        )}

        {!isExecutivesLoading && executives.length === 0 && (
          <p className={styles.message}>
            Sorry, no results.
            {UserPolicy.canRequestProfile(currentUser) && (
              <>
                {' '}
                <AnchorLink to={Routes.newRequestPath()} label="Click Here" /> to request a Profile and we&#39;ll
                prepare it from scratch!
              </>
            )}
          </p>
        )}

        <ProfilesList
          currentPage={executivesMeta.currentPage}
          isSelectable={isAnyExportAvailable}
          maxPages={maxPage}
          highlights={executivesMeta.highlights}
          highlightedKeywords={executivesMeta.highlightedKeywords}
          onLoadPage={onExecutivesPageLoad}
          onProfileSelect={handleProfileSelection}
          perPage={perPage}
          profiles={executives}
          selectedProfileIds={selectedProfileIds}
          totalCount={executivesMeta.totalCount}
          currentUser={currentUser}
        />
      </Tab>

      {!isExecutivesLoading && boardMembersMeta.totalCount > 0 && (
        <Tab title={renderTabTitle('Board of Directors', boardMembersMeta.totalCount, isBoardMembersLoading)}>
          {isAnyExportAvailable && (
            <Actions
              onBackgroundExport={onBackgroundExport}
              currentUser={currentUser}
              onSelectedProfileIdsChange={handleAllProfileSelection}
              profiles={boardMembers}
              selectedProfileIds={selectedProfileIds}
              filters={filters}
              keywords={boardMembers.highlightedKeywords}
            />
          )}

          <ProfilesList
            currentPage={boardMembersMeta.currentPage}
            isSelectable={isAnyExportAvailable}
            maxPages={maxPage}
            highlights={boardMembersMeta.highlights}
            highlightedKeywords={boardMembersMeta.highlightedKeywords}
            onLoadPage={onBoardMembersPageLoad}
            onProfileSelect={handleProfileSelection}
            perPage={perPage}
            profiles={boardMembers}
            selectedProfileIds={selectedProfileIds}
            totalCount={boardMembersMeta.totalCount}
          />
        </Tab>
      )}
    </Tabs>
  );
};

const ProfilesMetaShape = PropTypes.shape({
  currentPage: PropTypes.number,
  highlightedKeywords: PropTypes.arrayOf(PropTypes.string),
  highlights: PropTypes.shape(),
  totalCount: PropTypes.number,
});

Profiles.propTypes = {
  boardMembers: PropTypes.arrayOf(ProfilePresenter.shape()).isRequired,
  boardMembersMeta: ProfilesMetaShape,
  currentUser: CurrentUserPresenter.shape().isRequired,
  executives: PropTypes.arrayOf(ProfilePresenter.shape()).isRequired,
  executivesMeta: ProfilesMetaShape,
  filters: PropTypes.shape().isRequired,
  isBoardMembersLoading: PropTypes.bool.isRequired,
  isExecutivesLoading: PropTypes.bool.isRequired,
  maxPage: PropTypes.number.isRequired,
  onBackgroundExport: PropTypes.func.isRequired,
  onBoardMembersPageLoad: PropTypes.func.isRequired,
  onExecutivesPageLoad: PropTypes.func.isRequired,
  perPage: PropTypes.number.isRequired,
};

export default Profiles;
